import React, { useState } from 'react';
import { toast } from 'react-toastify';
import './index.scss';
import logo from '../../assets/img/logo-vikings.png';
import Loader from '../../components/loader';
import createContact from '../../services/contact';
import 'react-toastify/dist/ReactToastify.css';

export default function contactUs() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneCode, setPhoneCode] = useState(0);
  const [phone, setPhone] = useState(0);
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Error creating message');

  function renderMsgError() {
    if (error) {
      return (
        <p className="error-txt">{`${errorMessage}.`}</p>
      );
    }
    return null;
  }

  function saveContact() {
    setError(false);
    setLoading(true);
    setErrorMessage('');
    createContact(name, email, phone, phoneCode, message)
      .then(() => {
        setLoading(false);
        setError(true);
        setErrorMessage('Message sent successfully');
        setInterval(() => {
          setError(false);
          setErrorMessage('');
        }, 2000);
      })
      .catch((resp) => {
        const err = resp.response;
        setError(true);
        setLoading(false);
        if (err.data.code === 'missing_parameters') {
          setErrorMessage('Missing fields');
          toast.error('Missing fields');
        }
      });
  }

  return (
    <div className="contact-page">
      <div className="content-side">
        <div>
          <img src="assets/icon_contact.png" alt="contact" />
        </div>
        <h4>Contact</h4>
        <p>Fill up the form and out team will</p>
        <p>get back as soon as possible.</p>
      </div>
      <div className="contact-side">
        { !loading
          ? (
            <form className="contact-box">
              <img className="logo-vikings" src={logo} alt="" />
              <div className="line-form">
                <span>NAME</span>
                <input
                  type="text"
                  placeholder="Enter your name"
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </div>
              <div className="line-form">
                <span>E-MAIL</span>
                <input
                  type="email"
                  placeholder="Enter your e-mail"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>
              <div className="line-form">
                <div className="line-form-container">
                  <div className="line-phone-container">
                    <span>PHONE CODE</span>
                    <input
                      type="number"
                      placeholder="Enter your phone code"
                      className="phone-code-input"
                      onChange={(e) => {
                        setPhoneCode(e.target.value);
                      }}
                    />
                  </div>
                  <div className="line-phone-container">
                    <span>PHONE</span>
                    <input
                      type="number"
                      placeholder="Enter your phone"
                      className="phone-input"
                      onChange={(e) => {
                        setPhone(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="line-form">
                <span>Message</span>
                <textarea
                  placeholder="How can we help you?"
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                />
              </div>
              <button type="submit" className="btn-gral send-btn" onClick={saveContact}>Send</button>
              {renderMsgError()}
            </form>
          )
          : (
            <div className="login-side">
              <Loader />
            </div>
          )}
      </div>
    </div>
  );
}
