import React from 'react';
import './styles.scss';
import GridBigSize from '../../components/gridBigSize';

export default function IntermediateCategory() {
  return (
    <>
      <div className="content-category-grid">
        <h3 className="title-cat">Custom Mylar Bags</h3>
        <p className="down-text-cat">Sticker label applied to one side of bag</p>
        <GridBigSize />
      </div>
    </>
  );
}
