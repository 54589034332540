import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import TableCartView from '../../components/tableCartView';
import GradientHeader from '../../components/gradientHeader';
import ResumeOrder from '../../components/resumeOrder';
import ContentCart from '../../components/contentCart';
import Loader from '../../components/loader';
import OrderApproval from '../../components/orderApproval';
import './styles.scss';
import '../cart/styles.scss';
import { changeStatusOrder, getOrder } from '../../services/order';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function OrderConfirm() {
  const query = useQuery();
  const orderHash = query.get('order');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [items, setItems] = useState([]);
  const [order, setOrder] = useState(null);
  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    setError(false);
    getOrder(orderHash)
      .then((data) => {
        setItems(data.orderItems);
        setOrder(data.order);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  }, [orderHash]);

  const confirm = () => {
    setLoading(true);
    changeStatusOrder(orderHash, 'APPROVED')
      .then(() => {
        setLoading(false);
        localStorage.removeItem('sessionToken');
        history.push('/order-success');
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  };

  let body = order ? (
    <>
      <GradientHeader
        title="The order was created successfully"
        description=""
      />
      <ContentCart>
        <ResumeOrder items={[
          { title: 'Order Number', value: order.id },
          { title: 'Order Date', value: new Date(order.createdAt).toLocaleDateString() },
          { title: 'Address', value: `${order.address}, ${order.city},  ${order.state}` },
        ]}
        />
        <div className="content-table">
          <div className="top-table">
            <p>Description</p>
            <p>Quantity</p>
            <p>Price / piece</p>
          </div>
          {items.map((item) => (
            <TableCartView key={item.id} item={item} order={order} />
          ))}
          <div className="total-amount">
            <div className="info-order">
              <h5>Shipping Price:</h5>
              <h6>{`$ ${Number(order.shippingPrice).toFixed(2)}`}</h6>
            </div>
            <div className="info-order">
              <h5>Total Amount:</h5>
              <h6>{`$ ${Number(order.priceTotal).toFixed(2)}`}</h6>
            </div>
          </div>
        </div>
        <hr />
        <OrderApproval order={order} changeStatus={confirm} />
      </ContentCart>
    </>
  ) : null;

  if (loading) {
    body = (
      <div className="content-loader">
        <Loader />
      </div>
    );
  }

  if (error) {
    body = <h2>Error</h2>;
  }

  return <>{body}</>;
}
