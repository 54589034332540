import React, { useState, useContext } from 'react';
import './styles.scss';
import { Link, useHistory } from 'react-router-dom';
import logo from '../../assets/img/logo-vikings.png';
import { loginService } from '../../services/auth';
import Loader from '../../components/loader';
import { Context } from '../../context';

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { name, setName } = useContext(Context);
  const [errorMessage, setErrorMessage] = useState('The username or password is incorrect.');
  const history = useHistory();

  function renderMsgError() {
    if (error) {
      return (
        <p className="error-txt">{`${errorMessage}.`}</p>
      );
    }
    return null;
  }

  function login() {
    setLoading(true);
    loginService(email, password)
      .then((data) => {
        localStorage.setItem('token', data.token);
        setName({
          name: name.data,
        });
        setLoading(false);
        setEmail('');
        setPassword('');
        history.push('/');
        window.location.reload();
      })
      .catch((resp) => {
        const err = resp.response;
        setError(true);
        setLoading(false);
        if (err.data.code === 'missing_parameters') {
          setErrorMessage('Missing Fields');
        }
        if (err.data.code === 'authentication_failed') {
          setErrorMessage('Invalid email or password');
        }
      });
  }

  return (
    <div className="login-page">
      <div className="gradient-side">
        <div className="login-cont">
          <h4>Welcome</h4>
          <p>Please enter your details to access</p>
          <p>your acount.</p>
        </div>
      </div>
      <div className="login-side">
        { !loading
          ? (
            <>
              <form className="login-box form-login-container">
                <img className="logo-vikings" src={logo} alt="" />
                <div className="brand">Log in</div>
                <div className="line-form">
                  <span>E-EMAIL</span>
                  <input
                    type="email"
                    placeholder="Enter your e-mail"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </div>
                <div className="line-form">
                  <span>PASSWORD</span>
                  <input
                    type="password"
                    autoComplete="new-password"
                    placeholder="Enter your password"
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                  <p className="forgot">
                    <Link className="link" to="/recover-password">Forgot password</Link>
                  </p>
                </div>
                <div className="button-submit-container">
                  <button type="submit" className="btn-gral" onClick={login}>Login</button>
                </div>
                {renderMsgError()}
                <p className="text-gradient">
                  Not a member?
                  {' '}
                  <Link className="link" to="/signup">Sign up</Link>
                  {' '}
                </p>
              </form>

            </>
          )
          : (
            <div className="login-side">
              <Loader />
            </div>
          )}
      </div>
    </div>
  );
}
