import React from 'react';
import './styles.scss';

export default function AddToCart() {
  return (
    <div className="h2-space">
      <button className="btn-gral" type="button">Add to cart</button>
    </div>
  );
}
