import React from 'react';
import './styles.scss';
import { Link } from 'react-router-dom/cjs/react-router-dom';

export default function PromoBannerHome() {
  return (
    <div className="promo-banner-home" data-aos="fade-left">
      <div className="text-banner-home" data-aos="zoom-out" data-aos-delay="600">
        <h3>
          Get 50 3x3 die cut stickers
          {' '}
          <br />
          For $20!
        </h3>
        <Link to="/stickers">
          <button className="btn-gral" type="button">
            Order Stickers!
          </button>
        </Link>
      </div>
      <div className="absolute-content-img" data-aos="zoom-in" data-aos-delay="900">
        <div className="content-img-hover">
          <div className="tl" />
          <div className="tr" />
          <div className="bl" />
          <div className="br" />
          <img src="/assets/brand-banner.png" alt="banner-brand" />
        </div>
      </div>
    </div>
  );
}
