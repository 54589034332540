import axios from 'axios';

export default function getShippingPrice(sessionToken, zipCode) {
  return axios({
    method: 'post',
    data: { zipCode },
    url: `${process.env.REACT_APP_API_URL}/carts/me/shipping-price?sessionToken=${sessionToken}`,
  })
    .then((response) => response.data.price);
}
