import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Home from './pages/home';
import Category from './pages/category';
import ContactUs from './pages/contact-us';
import ElementShop from './pages/elementShop';
import Cart from './pages/cart';
import OrderConfirm from './pages/orderConfirm';
import IntermediateCategory from './pages/intermediateCategory';
// import { getCartWithItems } from './services/cart';
import validateSession from './services/session';
import { Context } from './context';
import Loader from './components/loader';
import './App.scss';
import PayConfirm from './pages/payConfirm';
import OrderSuccess from './pages/orderSuccess';
import Library from './pages/library';
import Footer from './components/footer';
import Header from './components/header';
import PayFailure from './pages/payFailure';
import ElementLibrary from './pages/elementLibrary';
import ValidateMail from './pages/validateMail';
import Login from './pages/login';
import UserProfile from './pages/userProfile';
import SignUp from './pages/signup';
import AddProduct from './pages/add-product';
import UserCreated from './pages/userCreated';
import RecoverPassword from './pages/recoverPassword';
import ScrollToTop from './components/scrollToTop';
import RestorePassword from './pages/restorePassword';
import getLibraryProducts from './services/library-products';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const sessionToken = localStorage.getItem('sessionToken') || '';
  const { setCart } = useContext(Context);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getLibraryProducts()
      .then((data) => {
        setProducts(data);
      });
  }, [products.id]);

  useEffect(() => {
    setLoading(true);
    validateSession(sessionToken)
      .then((data) => {
        setCart({
          items: data.cartItems,
          sessionToken: data.tokenSession,
          itemsCount: data.counterItems,
          data: data.cart,
          coupon: data.coupon,
        });
        setLoading(false);
        if (data.sessionToken !== sessionToken) {
          localStorage.setItem('sessionToken', data.tokenSession);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <div className="box-content-loader loading">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <BrowserRouter>
        <Header products={products} />
        <main>
          <ScrollToTop />
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/category/:categoryId/element">
              <ElementShop />
            </Route>
            <Route exact path="/cart">
              <Cart />
            </Route>
            <Route exact path="/library">
              <Library />
            </Route>
            <Route exact path="/item">
              <ElementLibrary />
            </Route>
            <Route exact path="/order-confirm">
              <OrderConfirm />
            </Route>
            <Route exact path="/intermediate-category">
              <IntermediateCategory />
            </Route>
            <Route exact path="/pay-confirm">
              <PayConfirm />
            </Route>
            <Route exact path="/order-success">
              <OrderSuccess />
            </Route>
            <Route path="/user-profile">
              <UserProfile />
            </Route>
            <Route exact path="/pay-failure">
              <PayFailure />
            </Route>
            <Route exact path="/validate-mail">
              <ValidateMail />
            </Route>
            <Route exact path="/login">
              <Login />
            </Route>
            <Route exact path="/signup">
              <SignUp />
            </Route>
            <Route path="/restore-password">
              <RestorePassword />
            </Route>
            <Route exact path="/recover-password">
              <RecoverPassword />
            </Route>
            <Route exact path="/product-library/:id" component={AddProduct} />
            <Route exact path="/user-created">
              <UserCreated />
            </Route>
            <Route exact path="/contact">
              <ContactUs />
            </Route>
            <Route path="/:categoryUrlCode">
              <Category />
            </Route>
          </Switch>
        </main>
        <Footer />
      </BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default App;
