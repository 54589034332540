import React, { useState, useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import { getUsersById } from '../../services/users';
import './styles.scss';

export default function ContactBox({
  setEmail, errorEmail, setAddress,
  address, zipCode, setZipCode, errorEmptyField,
  phone, name, city, state, setPhone, setName, setCity, setState,
}) {
  const token = localStorage.getItem('token') || '';
  const [emailUser, setEmailUser] = useState(null);

  useEffect(() => {
    if (token) {
      const decoded = jwtDecode(token);
      setEmailUser(decoded.email);
      setEmail(decoded.email);
      getUsersById(decoded.sub)
        .then((data) => {
          setAddress(data[0].address);
          setZipCode(data[0].zipCode);
          setPhone(data[0].phone);
          setName(data[0].name);
          setCity(data[0].city);
          setState(data[0].state);
        });
    }
  }, []);

  const handleZipCodeChange = (e) => {
    const { value } = e.target;
    if (value.length <= 5) {
      setZipCode(value);
    }
  };

  return (
    <div className="box-form contact-box">
      <h5 className="title-box-form">Contact Info</h5>
      <input
        type="email"
        value={emailUser ?? null}
        className="input-gral"
        placeholder="Email address"
        disabled={emailUser !== null}
        onChange={(e) => setEmail(e.target.value)}
      />
      {errorEmail && (
        <div className="error-msg email">
          <span>Invalid email</span>
        </div>
      )}
      <input
        type="text"
        value={name}
        className="input-gral"
        placeholder="Name"
        onChange={(e) => setName(e.target.value)}
      />
      <input
        type="number"
        value={phone}
        className="input-gral"
        placeholder="Phone"
        onChange={(e) => setPhone(e.target.value)}
      />
      <input
        type="text"
        value={city}
        className="input-gral"
        placeholder="City"
        onChange={(e) => setCity(e.target.value)}
      />
      <input
        type="text"
        value={state}
        className="input-gral"
        placeholder="State"
        onChange={(e) => setState(e.target.value)}
      />
      <input
        type="text"
        value={address}
        className="input-gral"
        placeholder="Address"
        onChange={(e) => setAddress(e.target.value)}
      />
      <input
        type="number"
        value={zipCode}
        className="input-gral"
        placeholder="Zip code"
        onChange={handleZipCodeChange}
      />
      {errorEmptyField && (
        <div className="error-msg">
          <span>Check the fields, cannot be empty.</span>
        </div>
      )}
    </div>
  );
}
