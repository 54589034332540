import React, { useState } from 'react';
import './styles.scss';
import { NavLink, useLocation } from 'react-router-dom';
import logo from '../../assets/img/logo-vikings.png';
import Check from '../../assets/img/check.png';
import { restorePass } from '../../services/users';
import Loader from '../../components/loader';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function RestorePassword() {
  const query = useQuery();
  const emailToken = query.get('token');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Something went wrong');
  const [successValidation, setSuccessValidation] = useState(false);
  function renderMsgError() {
    // aca switch
    if (error) {
      switch (errorMessage) {
        case 'notsamepass':
          return setErrorMessage('Las contraseñas deben ser iguales.');
        case 'notemptyfields':
          return setErrorMessage('No pueden estar los campos vacios.');
        case 'lengthlessthan8':
          return setErrorMessage('El tamaño de la contraseña no puede ser menor a 8.');
        default:
          break;
      }
      return (
        <p className="error-txt">{`${errorMessage}.`}</p>
      );
    }
    return null;
  }

  function restorePassword() {
    setLoading(true);
    restorePass(newPassword, confirmPassword, emailToken)
      .then((data) => {
        localStorage.setItem('token', data.token);
        setLoading(false);
        setConfirmPassword('');
        setSuccessValidation(true);
        setNewPassword('');
      })
      .catch((resp) => {
        const err = resp.response;
        setError(true);
        setLoading(false);
        if (err.data.code === 'missing_parameters') {
          setErrorMessage('Missing Fields');
        }
        if (err.data.code === 'authentication_failed') {
          setErrorMessage('Invalid email or password');
        }
      });
  }

  function passwordValidation(newPass, confirmPass) {
    setError(false);
    if (newPass === '' || confirmPass === '' || !newPass || !confirmPass) {
      setError(true);
      setErrorMessage('notemptyfields');
    } else if (newPass.length < 8 || confirmPass.length < 8) {
      setError(true);
      setErrorMessage('lengthlessthan8');
    } else if (newPass !== confirmPass) {
      setError(true);
      setErrorMessage('notsamepass');
    } else {
      restorePassword();
    }
  }

  return (
    <div className="login-page">
      <div className="gradient-side">
        <div className="login-cont">
          <h4>Welcome</h4>
          <p>Type a new password and confirm it</p>
          <p>do not forget it.</p>
        </div>
      </div>
      <div className="restore-password-side">
        { !loading
          ? (

            <div>
              {!successValidation ? (
                <>
                  <div className="login-box">
                    <img className="logo-vikings" src={logo} alt="" />
                    <div className="brand">RESET YOUR PASSWORD</div>
                    <div className="line-form">
                      <span>NEW PASSWORD</span>
                      <input
                        type="password"
                        placeholder="Enter your new password"
                        onChange={(e) => {
                          setNewPassword(e.target.value);
                        }}
                      />
                    </div>
                    <div className="line-form">
                      <span>CONFIRM YOUR NEW PASSWORD</span>
                      <input
                        type="password"
                        placeholder="Enter your new password"
                        onChange={(e) => {
                          setConfirmPassword(e.target.value);
                        }}
                      />
                    </div>

                    <div className="button-submit-container">
                      <button type="submit" className="btn-gral" onClick={() => passwordValidation(newPassword, confirmPassword)}>Recover</button>
                    </div>
                    {renderMsgError()}
                  </div>

                </>
              )
                : (
                  <div className="success-message">
                    <img src={Check} alt="check" />
                    <h3>
                      Done! Your password has been changed successfully!
                    </h3>
                    <div className="sign-back-to-stickers">
                      <NavLink to="/login" className="text-decoration-none">
                        <h3>Back to Login</h3>
                      </NavLink>
                    </div>
                  </div>
                )}
            </div>
          )
          : (
            <div className="login-side">
              <Loader />
            </div>
          )}
      </div>
    </div>
  );
}
