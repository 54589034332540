import React from 'react';
import BoxItem from '../boxItem';
import './styles.scss';

export default function ItemsBoxMuliple() {
  return (
    <div
      className="item-content"
      // data-aos="zoom-in"
      // data-aos-delay="300"
    >
      <BoxItem
        title="Review your order"
        text="Get a mail with the final design blueprint and confirm it’s to your taste or request changes until you’re happy."
        imgSrc="/assets/dishwasher.png"
      />
      <BoxItem
        title="Fast and reliable shipping"
        text="We typically ship out your stickers in 3 – 7 working days after getting your order."
        imgSrc="/assets/truck.png"
      />
      <BoxItem
        title="Premium quality materials"
        text="We use only premium-grade, eco-friendly materials for every order."
        imgSrc="/assets/weather.png"
      />
    </div>
  );
}
