import React from 'react';

export default function gradientHeader({ title, description }) {
  return (
    <div className="top-pay-banner">
      <div className="center-text-banner">
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
    </div>
  );
}
