import React from 'react';
import './styles.scss';
import FilterLibrary from '../../components/filterLibrary';
import AddToCart from '../../components/addToCart';
import BannerImg from '../../components/bannerImg';

export default function ElementLibrary() {
  return (
    <>
      <div className="content-library">
        <div className="filter-library-side">
          <FilterLibrary />
        </div>
        <div className="library-grid-content sticker-side-element">
          <img src="/assets/stickerempty.png" alt="empty" />
          <div className="text-element-sticker">
            <h4>Sticker name</h4>
            <p>
              Lorem ipsum dolor sit amet,
              consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua.
            </p>
            <div className="custom-sizes">
              <div className="box-form input-library">
                <label htmlFor="width">
                  Width
                  <input type="number" className="input-gral" id="width" placeholder="Width" />
                </label>
              </div>
              <span>x</span>
              <div className="box-form input-library">
                <label htmlFor="height">
                  Height
                  <input type="number" className="input-gral" id="height" placeholder="Height" />
                </label>
              </div>
            </div>
            <div className="box-form input-library">
              <label htmlFor="quantity">
                Quantity
                <input type="number" className="input-gral" id="quantity" placeholder="Quantity" />
              </label>
            </div>
            <div className="box-price">
              <h2>$ 3.10</h2>
            </div>
            <div>
              <AddToCart />
            </div>
          </div>
        </div>
      </div>
      <div>
        <BannerImg />
      </div>
    </>
  );
}
