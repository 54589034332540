/* eslint-disable no-nested-ternary */
import React from 'react';
import './styles.scss';

export default function BoxSizeSelector({
  label, onClick, isChecked, name,
}) {
  return (
    <label htmlFor={label}>
      <div>
        <span aria-hidden="true" className="checkcontainer" onClick={onClick}>
          <input type="radio" name={name} id={label} checked={isChecked} />
          <div className="container-discount">
            <p>
              {label}
            </p>
          </div>
          <span className="checkmark" />
        </span>
      </div>
    </label>
  );
}
