import React, { useState } from 'react';

export const Context = React.createContext();

export const ContextProvider = ({ children }) => {
  const [cart, setCart] = useState({
    sessionToken: '',
    data: {},
    items: [],
    itemsCount: 0,
    coupon: {},
  });

  const [name, setName] = useState({
    name: '',
  });

  const appState = {
    cart,
    setCart,
    name,
    setName,
  };

  return (
    <Context.Provider value={appState}>
      {children}
    </Context.Provider>
  );
};

export const ContextConsumer = Context.Consumer;
