import React from 'react';
import GradientHeader from '../../components/gradientHeader';
import ResumeOrder from '../../components/resumeOrder';
import ContentCart from '../../components/contentCart';
import './index.scss';

export default function orderSuccess() {
  const items = [{
    title: 'Tracking Number',
    value: '02931209458467',
  }, {
    title: 'Order Date',
    value: '18 March, 2021',
  }, {
    title: 'Address',
    value: 'Lorem ipsum 907',
  }];

  return (
    <>
      <GradientHeader
        title="Congratulations"
        description="Your order was approved"
      />
      <ContentCart>
        <div className="content-images">
          <img src="assets/icon.png" alt="icon1" />
          <img src="assets/box.png" alt="icon2" />
          <img src="assets/roll.png" alt="icon3" />
        </div>
        <p className="description-success">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
          incididunt ut labore et dolore magna aliqua.
        </p>
        <ResumeOrder items={items} />
      </ContentCart>
    </>
  );
}
