import React from 'react';
import './styles.scss';

export default function BoxGridSize({ description, size }) {
  return (
    <div className="box-grid-size">
      <p className="description-size">{description}</p>
      <p>{size}</p>
    </div>
  );
}
