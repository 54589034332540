import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import './styles.scss';
import _ from 'lodash';
import { Popover } from 'react-tiny-popover';
import fileUpload from '../../services/product';
import LoaderMini from '../loaderMini';

export default function UploadFile({ setFileId }) {
  const history = useHistory();
  const [file, setFile] = useState('select your file');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    function checkMobile() {
      setIsMobile(window.innerWidth <= 768);
    }

    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, []);

  function handleChange(e) {
    const filename = _.get(e, 'target.files[0].name', 'select your file');
    const sessionToken = localStorage.getItem('sessionToken');
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    setFile(filename);
    if (!sessionToken) {
      history.push('/');
    }
    setLoading(true);
    fileUpload(sessionToken, formData)
      .then(({ id }) => {
        setFileId(id);
        setLoading(false);
        setError(false);
        setIsPopoverOpen(false);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
        setIsPopoverOpen(true);
      });
  }

  const containerStyles = {
    position: 'absolute',
    overflow: 'visible',
    borderRadius: '4px',
    padding: '8px',
    top: isMobile ? '170rem' : '60.5rem',
    left: isMobile ? '55px' : '160px',
  };

  return (
    <>
      <div className="content-file">
        <Popover
          isOpen={isPopoverOpen}
          onClickOutside={() => setIsPopoverOpen(false)}
          content={(
            <div className="popover-container-left">
              Error to upload file, check the format and size
            </div>
          )}
          containerStyle={containerStyles}
        >
          {!loading ? (
            <>
              <input
                type="file"
                name="fileUp"
                id="file"
                className="inputfile"
                onChange={handleChange}
              />
              <label htmlFor="file">
                <span>{error ? 'Error to upload file' : file}</span>
              </label>
            </>
          ) : (
            <LoaderMini />
          )}
        </Popover>
      </div>
      <p className="skip">
        <span>OR</span>
        {' '}
        Skip this step & email artwork later
      </p>
      <br />
      <h3 className="file-format-supported">
        Supported Files: PDF, JPG, JPEG, PNG, SVG
      </h3>
      <h3 className="size-supported">
        (max 5mb)
      </h3>
    </>
  );
}
