import axios from 'axios';

export default function getLibraryProducts(filter) {
  const urlBuild = filter ? `?category=${filter}` : '';
  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_API_URL}/libraryproducts${urlBuild}`,
  })
    .then((resp) => resp.data);
}
