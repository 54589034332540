import axios from 'axios';

export default function createContact(name, email, phone, codePhone, content) {
  return axios({
    method: 'post',
    data: {
      name,
      email,
      phone,
      codePhone,
      content,
    },
    url: `${process.env.REACT_APP_API_URL}/create-contact`,
  })
    .then((resp) => resp.data);
}
