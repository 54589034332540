import React from 'react';
import './styles.scss';

function BannerImg() {
  return (
    <div className="content-gral-banner">
      <h4>Lorem ipsum dolor sit</h4>
      <div className="text-container">
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
          incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
          exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat dolore magna aliqua.

        </p>
      </div>
      <div className="img-container">

        <div className="small-img">
          <img src="/assets/ej1.jpeg" alt="related1" />
        </div>
        <div className="big-img">
          <img src="/assets/ej3.jpeg" alt="related2" />
        </div>
        <div className="small-img2">
          <img src="/assets/ej4.jpeg" alt="related3" />
        </div>
      </div>
    </div>

  );
}

export default BannerImg;
