import React, { useEffect } from 'react';
import './styles.scss';
import Aos from 'aos';
import { Link } from 'react-router-dom';

import GridSmall from '../../components/gridHome';
import FollowUsInstagram from '../../components/follow-us-instagram';
import GridLibraryHome from '../../components/gridLibraryHome';
// import SponsorsBox from '../../components/sponsors-box';
import PromoBannerHome from '../../components/promoBannerHome';
import ItemsBoxMuliple from '../../components/itemsBoxMultiple';
import 'aos/dist/aos.css';

export default function Home() {
  useEffect(() => {
    Aos.init({
      duration: 500,
      delay: 150,
      mirror: false,
      once: true,
    });
  });
  return (
    <div className="home-container">
      <div className="top-init-banner">
        <div className="banner-side" data-aos="fade-down">
          <div className="are-u-ready-section">
            <h2 data-aos="zoom-out">ARE U READY?</h2>
            <h3 data-aos="zoom-out" data-aos-delay="400">to take your brand to a new level</h3>
            <Link to="/signup">
              <div data-aos="zoom-out" data-aos-delay="700" type="button" className="btn-gral">Sign up now!</div>
            </Link>
          </div>
        </div>
        <div className="content-side-grid">
          <div className="box-grid-init">
            <div className="grid-titles">
              <h2 data-aos="fade-left" data-aos-delay="700">Welcome to the world of stickers</h2>
              <p data-aos="fade-left" data-aos-delay="1000">Check out our products</p>
            </div>
            <GridSmall />
          </div>
        </div>
      </div>
      <div className="home-promo-banner">
        <PromoBannerHome />
      </div>
      <div className="home-item-box-multiple">
        <ItemsBoxMuliple />
      </div>
      <div className="grid-library-home-container">
        <div className="grid-library-home-content">
          <div className="our-designs-title-button">
            <div data-aos="fade-up" className="grid-titles grid-titles-center">
              <h2 data-aos="fade-rigth" data-aos-delay="200">You can buy our designs too</h2>
              <p data-aos="fade-left" data-aos-delay="400">Take a look at our designs of the week</p>
            </div>
            <Link to="/library">
              <div className="btn-container">
                <button type="button" className="btn-gral" data-aos="fade-up" data-aos-delay="400">Go library now!</button>
              </div>
            </Link>
          </div>
          <GridLibraryHome />
        </div>
      </div>
      <div className="home-follow-us-container">
        <div className="home-follow-us-content">
          <div>
            <h2 data-aos="fade-rigth" data-aos-delay="200">
              Follow us in instagram
              {' '}
              <a href="https://www.instagram.com/vikingstickers/" target="_blank" rel="noreferrer">
                <span>@vikingstickers</span>
              </a>
            </h2>
          </div>
          <FollowUsInstagram />
        </div>
      </div>
      {
      /*
      <div className="home-sponsors-box-container">
        <div className="home-sponsors-box-content">
          <div>
            <h2
              data-aos="fade-rigth"
              data-aos-delay="200">Trusted by amazing companies all over the world
            </h2>
          </div>
          <SponsorsBox />
        </div>
      </div> */
      }
    </div>
  );
}
