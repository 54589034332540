import axios from 'axios';

export function loadQuantity(id, quantity) {
  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_API_URL}/carts/me/items/edit`,
    data: {
      id,
      quantity,
    },
  })
    .then((resp) => resp.data);
}

export function loadQuantityLibraryItem(id, quantity) {
  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_API_URL}/carts/me/library-items/edit`,
    data: {
      id,
      quantity,
    },
  })
    .then((resp) => resp.data);
}

export default {
  loadQuantity,
};
