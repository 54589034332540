/* eslint-disable import/order */
import React, { useState, useEffect, useContext } from 'react';
import GradientHeader from '../../components/gradientHeader';
import Loader from '../../components/loader';
import './index.scss';
import { getCartWithItems } from '../../services/cart';
import { Context } from '../../context';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

export default function payConfirm() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const sessionToken = localStorage.getItem('sessionToken');
  const { setCart } = useContext(Context);

  const cleanCart = () => {
    localStorage.removeItem('sessionToken');
    setCart((cartActual) => ({
      ...cartActual,
      sessionToken: '',
      data: {},
      items: [],
      itemsCount: 0,
    }));
  };

  useEffect(() => {
    toast.success('Your order has been created, we send an email with the details');
    setLoading(true);
    setError(false);
    getCartWithItems(sessionToken)
      .then(() => {
        setLoading(false);
        const timer1 = setTimeout(() => cleanCart(), 6000);
        return () => {
          clearTimeout(timer1);
        };
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  }, []);

  let body = (
    <>
      <GradientHeader
        title="The order was created sucessfully"
        description="We will send you an email where you can approve the order."
      />
    </>
  );

  if (loading) {
    body = (
      <>
        <Loader />
      </>
    );
  }

  if (error) {
    body = (
      <>
        <h3>Error</h3>
      </>
    );
  }

  return (
    <>
      {body}
    </>
  );
}
