import React, { useEffect, useState } from 'react';
import './styles.scss';
import { useParams, useHistory } from 'react-router-dom';
import GridBig from '../../components/gridBig';
import getCategoriesById from '../../services/category';
import Loader from '../../components/loader';

function getIdCategory(categoryUrlCode) {
  switch (categoryUrlCode.toLowerCase()) {
    case 'stickers':
      return process.env.REACT_APP_ID_CATEGORY_STICKERS;
    case 'labels':
      return process.env.REACT_APP_ID_CATEGORY_LABELS;
    case 'banners':
      return process.env.REACT_APP_ID_CATEGORY_BANNERS;
    case 'magnets':
      return process.env.REACT_APP_ID_CATEGORY_MAGNETS;
    default:
      return categoryUrlCode;
  }
}

export default function Category() {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState({
    name: '',
    description: '',
  });

  const { categoryUrlCode } = useParams();
  const history = useHistory();

  useEffect(() => {
    const idCategory = getIdCategory(categoryUrlCode);
    if (idCategory === -1) {
      history.push('/');
      return;
    }

    setLoading(true);
    setError(false);

    getCategoriesById({
      idCategory,
      query: {
        subcategories: true,
        file: true,
      },
    })
      .then((data) => {
        setCategory(data.category);
        const sortedCategories = data.subcategories.sort((a, b) => a.id - b.id);
        setCategories(sortedCategories);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  }, [categoryUrlCode, history]);

  let bodyCategory = (
    <>
      <h3 className="title-cat">{category.name}</h3>
      <p className="down-text-cat">{category.description}</p>
      <GridBig categories={categories} />
    </>
  );

  if (loading) {
    bodyCategory = (
      <div className="content-loader">
        <Loader />
      </div>
    );
  }

  if (error) {
    bodyCategory = <h3>There was an error getting the categories</h3>;
  }

  return (
    <div className="content-category-grid">{bodyCategory}</div>
  );
}
