import axios from 'axios';

export default function validateMail(token) {
  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_API_URL}/validate-mail`,
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((resp) => resp.data);
}
