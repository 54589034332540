import axios from 'axios';

export function loginService(email, password) {
  return axios({
    method: 'post',
    data: {
      email,
      password,
    },
    url: `${process.env.REACT_APP_API_URL}/login`,
  })
    .then((resp) => resp.data);
}

export function signupService(firstName, surName, email, password, repeatPassword,
  zipCode, address) {
  return axios({
    method: 'post',
    data: {
      firstName,
      surName,
      email,
      zipCode,
      address,
      password,
      repeatPassword,
    },
    url: `${process.env.REACT_APP_API_URL}/signup`,
  })
    .then((resp) => resp.data);
}
