import axios from 'axios';

export function getCartWithItems(sessionToken) {
  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_API_URL}/carts/me/get?sessionToken=${sessionToken}`,
  })
    .then((resp) => resp.data);
}

export function createCartItem(sessionToken, item) {
  return axios({
    method: 'post',
    data: item,
    url: `${process.env.REACT_APP_API_URL}/carts/me/items?sessionToken=${sessionToken}`,
  })
    .then((resp) => resp.data);
}

export function createCartLibraryItem(sessionToken, item) {
  return axios({
    method: 'post',
    data: item,
    url: `${process.env.REACT_APP_API_URL}/product-library/${item.id}/create?sessionToken=${sessionToken}`,
  })
    .then((resp) => resp.data);
}

export function removeCartItem(sessionToken, itemId) {
  return axios({
    method: 'post',
    data: {
      cartItemId: itemId,
    },
    url: `${process.env.REACT_APP_API_URL}/carts/me/items/remove?sessionToken=${sessionToken}`,
  })
    .then((resp) => resp.data);
}

export function confirmCart(sessionToken, email) {
  return axios({
    method: 'post',
    data: { email },
    url: `${process.env.REACT_APP_API_URL}/carts/me/confirm?sessionToken=${sessionToken}`,
  })
    .then((resp) => resp.data);
}

export function closeCart(sessionToken, userData) {
  return axios({
    method: 'post',
    data: userData,
    url: `${process.env.REACT_APP_API_URL}/carts/me/close?sessionToken=${sessionToken}`,
  })
    .then((resp) => resp.data);
}

export function removeAllCartItems(sessionToken) {
  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_API_URL}/carts/me/items/remove/all?sessionToken=${sessionToken}`,
  })
    .then((resp) => resp.data);
}

export function calculateTotalCart(items) {
  let total = 0;
  items.forEach((item) => {
    total += parseFloat(item.price);
  });
  return (total / 100).toFixed(2);
}

export function calculateTotalCart2(items) {
  let total = 0;
  items.forEach((item) => {
    total += parseFloat(item.price);
  });
  return total.toFixed(2);
}

export function calculatePriceItem(quantity, categoryId, proportion) {
  return axios({
    method: 'post',
    data: {
      quantity,
      categoryId,
      proportion,
    },
    url: `${process.env.REACT_APP_API_URL}/item/calculate-price`,
  })
    .then((resp) => resp.data);
}

export function calculateDefaultPricesItem(categoryId, proportion, custom) {
  return axios({
    method: 'post',
    data: {
      categoryId,
      proportion,
      custom,
    },
    url: `${process.env.REACT_APP_API_URL}/item/calculate-default-prices`,
  })
    .then((resp) => resp.data);
}

export function calculateCustomPriceItem(categoryId, proportion, quantity, custom) {
  return axios({
    method: 'post',
    data: {
      categoryId,
      proportion,
      quantity,
      custom,
    },
    url: `${process.env.REACT_APP_API_URL}/item/calculate-custom-price`,
  })
    .then((resp) => resp.data);
}

export function applyCouponToCart(coupon, cartId) {
  return axios({
    method: 'post',
    data: {
      coupon,
    },
    url: `${process.env.REACT_APP_API_URL}/cart/${cartId}/apply`,
  })
    .then((resp) => resp.data);
}
