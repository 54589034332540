import React, { useState } from 'react';
import './styles.scss';
import { NavLink } from 'react-router-dom';
import logo from '../../assets/img/logo-vikings.png';
import Check from '../../assets/img/check.png';
import { checkUserEmail } from '../../services/users';
import Loader from '../../components/loader';

export default function RestorePassword() {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Something went wrong');
  const [successValidation, setSuccessValidation] = useState(false);

  function renderMsgError() {
    if (error) {
      return (
        <p className="error-txt">{`${errorMessage}.`}</p>
      );
    }
    return null;
  }

  function restorePassword() {
    setLoading(true);
    checkUserEmail(email)
      .then(() => {
        setLoading(false);
        setEmail('');
        setSuccessValidation(true);
      })
      .catch((resp) => {
        const err = resp.response;
        setError(true);
        setLoading(false);
        if (err.data.code === 'missing_parameters') {
          setErrorMessage('Missing Fields');
        }
      });
  }

  return (
    <div className="login-page">
      <div className="gradient-side">
        <div className="login-cont">
          <h4>Welcome</h4>
          <p>Please enter your email in order</p>
          <p>to recover your password.</p>
        </div>
      </div>
      <div className="recovery-password-side">
        { !loading
          ? (

            <div>
              {!successValidation ? (
                <form className="login-box">

                  <img className="logo-vikings" src={logo} alt="" />
                  <div className="brand">Forgot your password?</div>
                  <div className="line-form">
                    <span>E-EMAIL</span>
                    <input
                      type="email"
                      placeholder="Enter your e-mail"
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </div>
                  <button type="submit" className="btn-gral" onClick={() => restorePassword(email)}>Recover</button>
                  {renderMsgError()}
                </form>
              )
                : (
                  <div className="success-message">
                    <img src={Check} alt="check" />
                    <h3>
                      Done! Check your email and follow the steps to restore your password!
                    </h3>
                    <div className="sign-back-to-stickers">
                      <NavLink to="/" className="text-decoration-none">
                        <h3>Back to Stickers</h3>
                      </NavLink>
                    </div>
                  </div>
                )}
            </div>
          )
          : (
            <div className="login-side">
              <Loader />
            </div>
          )}
      </div>
    </div>
  );
}
