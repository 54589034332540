import React from 'react';
import './styles.scss';
import BoxGrid from '../boxGrid';

export default function GridBig({ categories }) {
  const categoryOrder = {
    'Die Cut Stickers': 1,
    'Circle Stickers': 2,
    'Rectangle Stickers': 3,
    'Square Stickers': 4,
    'Oval Stickers': 5,
    'Bumper Stickers': 6,
    'Kiss Cut Stickers': 7,
    'Rounded Corner Stickers': 8,
  };

  const compareCategories = (a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();

    if (categoryOrder[nameA] < categoryOrder[nameB]) {
      return -1;
    }
    if (categoryOrder[nameA] > categoryOrder[nameB]) {
      return 1;
    }
    return 0;
  };

  const sortedCategories = categories.sort(compareCategories);

  return (
    <div className="content-grid-big">
      {sortedCategories.map((category) => (
        <div key={category.id}>
          {category.isDisabled ? (
            <BoxGrid
              category={category}
              className="disabled"
              isDisabled={category.isDisabled}
            />
          ) : (
            <BoxGrid
              category={category}
            />
          )}
        </div>
      ))}
    </div>
  );
}
