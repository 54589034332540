import React from 'react';
import './styles.scss';

export default function BoxItem({
  title, text, subtitle, imgSrc,
}) {
  return (
    <div className="box-item-content">
      <div className="icon-item">
        <img src={imgSrc} alt="form" />
      </div>
      <h5>{title}</h5>
      <p>{text}</p>
      <h4>{subtitle}</h4>
      {/* <a href>{link}</a> */}
    </div>
  );
}
