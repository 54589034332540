/* eslint-disable react/no-array-index-key */
import React from 'react';
import './index.scss';

export default function ResumeOrder({ items }) {
  return (
    <div className="resume-order">
      {items && items.map((item, index) => (
        <p key={index}>
          {item.title}
          <span>{item.value}</span>
        </p>
      ))}
    </div>
  );
}
