import React from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';

const API_IMAGE_URL = process.env.REACT_APP_API_IMAGE_URL;

export default function BoxGrid({ category, isHomePage = false, isDisabled }) {
  if (!category) {
    return <></>;
  }
  const imgSrc = category.file && category.file.path ? `${API_IMAGE_URL}${category.file.path}` : 'assets/icon.png';

  if (isHomePage) {
    if (category.name.includes('Stickers')) {
      return (
        <Link className="box-grid" to="/stickers">
          <img src={imgSrc} alt="icon" />
          <p>{category.name}</p>
        </Link>
      );
    }
    if (category.name.includes('Labels')) {
      return (
        <Link className="box-grid" to="/labels">
          <img src={imgSrc} alt="icon" />
          <p>{category.name}</p>
        </Link>
      );
    }
  } else {
    return (
      <div>
        {isDisabled
          ? (
            <div className="disabled">
              <div className="opacity">
                <img src={imgSrc} alt="icon" />
                <p>{category.name}</p>
              </div>
              <div className="not-opacity">
                <img src={imgSrc} alt="icon" />
                <p className="out-of-stock">Out of stock</p>
              </div>
            </div>
          ) : (
            <Link className="box-grid" to={`/category/${category.id}/element`}>
              <img src={imgSrc} alt="icon" />
              <p>{category.name}</p>
            </Link>
          )}
      </div>
    );
  }
}
