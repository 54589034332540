import React from 'react';
import BoxGridSize from '../boxGridSize';
import './styles.scss';

export default function GridBigSize() {
  return (
    <div className="content-grid-size-big">
      <BoxGridSize description="Gram to eighth" size="3x5" />
      <BoxGridSize description="Eighth to quarter" size="4x6" />
      <BoxGridSize description="Half OZ" size="5x8" />
      <BoxGridSize description="Ounce" size="6x9" />
      <BoxGridSize description="Pound" size="16x14" />
    </div>
  );
}
