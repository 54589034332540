import React from 'react';

export default function contentCart({ children }) {
  return (
    <div className="content-cart cart-view">
      <div className="content-cart-list">
        {children}
      </div>
    </div>
  );
}
