import React from 'react';
import './styles.scss';
import { NavLink } from 'react-router-dom';

export default function Footer() {
  return (
    <>
      <footer>
        <div className="content-footer">
          <a href="/" className="brand brand-footer">
            <img src="assets/logo-footer.png" alt="brand" />
          </a>
          <div className="links-footer">
            <a href="/stickers" className="brand brand-footer">
              stickers
            </a>
            <NavLink exact activeClassName="activeLemon" to="/labels">roll labels</NavLink>
            <NavLink exact activeClassName="activeYellow" to="/magnets">magnets</NavLink>
            <NavLink exact activeClassName="activeLemon" to="/banners">banners</NavLink>
            <NavLink exact activeClassName="active" to="/contact">contact us</NavLink>
          </div>
        </div>
        <div className="bottom-footer">
          <div className="aditional-info">
            <p>© 2024 Vikings Privacy & Terms Site map</p>
            <div className="address-info">
              <p>8243 Narcoossee Park Dr. #416, Orlando FL 32822, USA</p>
              <div>
                <p>Office Tel: (407) 857-0905 Ext. 221</p>
                <p>Fax: (407) 650-3441</p>
              </div>
            </div>
          </div>
          <div className="social-links-footer">
            <a href="https://www.instagram.com/vikingstickers/" target="_blank" rel="noreferrer">
              <img src="assets/ig.svg" alt="ig" />
            </a>
            <a href="https://www.facebook.com/vikingstickers/" target="_blank" rel="noreferrer">
              <img src="assets/fb.svg" alt="fb" />
            </a>
          </div>
        </div>
      </footer>

    </>
  );
}
