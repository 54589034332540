import axios from 'axios';

export default function fileUpload(sessionToken, file) {
  return axios({
    method: 'post',
    data: file,
    url: `${process.env.REACT_APP_API_URL}/upload-file?sessionToken=${sessionToken}`,
  })
    .then((resp) => resp.data);
}
