import React, { useEffect, useState } from 'react';
import './styles.scss';
import { Link, useLocation } from 'react-router-dom';
import Loader from '../../components/loader';
import validateMail from '../../services/validate-mail';

export default function ValidateMail() {
  const [loading, setLoading] = useState(false);
  const querystring = useLocation().search;
  const token = new URLSearchParams(querystring).get('token');

  useEffect(() => {
    setLoading(true);
    validateMail(token)
      .then((data) => {
        if (data.error) {
          setLoading(false);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <div className="box-center">
        <Loader />
      </div>
    );
  }
  return (
    <div className="content-email">
      <div className="validated-mail-content">
        <div>
          <img src="assets/email-validation.svg" alt="validated mail" />
        </div>
        <div className="txt-empty">
          <h3>Congrats! You have validated your Email</h3>
          <p>Start creating your own stickers right now!</p>
          <Link className="btn-gral btn-clean-cart" type="button" to="/login">Start Shopping</Link>
        </div>
      </div>
    </div>
  );
}
