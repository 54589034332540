import axios from 'axios';

export default function createMessage(newMessage, chatId, sessionToken) {
  return axios({
    method: 'post',
    data: newMessage,
    url: `${process.env.REACT_APP_API_URL}/messages/${chatId}?sessionToken=${sessionToken}`,
  })
    .then((resp) => resp.data);
}
