import axios from 'axios';

export default function getQuantityForCategory(categoryId) {
  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_API_URL}/quantity/${categoryId}`,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error('Error fetching quantity:', error);
      throw error;
    });
}
