import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export function getUserOrderById({ idUser }) {
  return axios({
    method: 'get',
    url: `${API_URL}/orders?user=${idUser}`,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
    .then((resp) => resp.data);
}

export function getUsersById(idUser) {
  return axios({
    method: 'get',
    url: `${API_URL}/users?user=${idUser}`,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
    .then((resp) => resp.data);
}

export function changeUserPassword(current, newPassword) {
  return axios({
    method: 'post',
    url: `${API_URL}/users/me/password`,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    data: {
      current,
      new: newPassword,
    },
  })
    .then((resp) => resp.data);
}

export function checkUserEmail(email) {
  return axios({
    method: 'post',
    url: `${API_URL}/checkUserEmail`,
    data: {
      email,
    },
  })
    .then((resp) => resp.data);
}

export function restorePass(newPassword, confirmNewPassword, emailToken) {
  return axios({
    method: 'post',
    url: `${API_URL}/restorePassword`,
    headers: { Authorization: `Bearer ${emailToken}` },
    data: {
      password: newPassword,
      repeatPassword: confirmNewPassword,
    },
  })
    .then((resp) => resp.data);
}
export default {
  getUserOrderById,
  getUsersById,
  changeUserPassword,
  checkUserEmail,
  restorePass,
};
