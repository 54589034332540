import React, { useEffect, useState } from 'react';
import './styles.scss';
import ItemLibrary from '../../components/itemLibrary';
import FilterLibrary from '../../components/filterLibrary';
import getLibraryProducts from '../../services/library-products';
import Loader from '../../components/loader';

export default function Library() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [filter, setFilter] = useState('');
  const [products, setProducts] = useState([]);

  useEffect(() => {
    setLoading(true);
    getLibraryProducts(filter)
      .then((data) => {
        setProducts(data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  }, [filter]);

  const setNewFilter = (newFilter) => {
    if (newFilter === filter) {
      setFilter('');
    } else {
      setFilter(newFilter);
    }
  };

  let bodyCategory = (
    <>
      <div className="content-library">
        <div className="filter-library-side">
          <h3>Categories</h3>
          <FilterLibrary setFilterSelected={setNewFilter} selectCategoryId={filter} />
        </div>
        <div className="library-grid-content">
          <h3 className="title-cat">Popular stickers</h3>
          <div className="grid-library">
            {products.map((product) => {
              return <ItemLibrary item={product} />;
            })}
          </div>
        </div>
      </div>
    </>
  );

  if (loading) {
    bodyCategory = (
      <>
        <div className="content-loader">
          <Loader />
        </div>
      </>
    );
  }

  if (error) {
    bodyCategory = (
      <h3>There was an error getting the categories</h3>
    );
  }

  return bodyCategory;
}
