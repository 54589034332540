import React from 'react';
import './styles.scss';
import { Link } from 'react-router-dom';

export default function GridHome() {
  const body = (
    <div className="content-grid" data-aos="fade-up" data-aos-delay="400">
      <Link className="box-grid box-grid-padding" to="/stickers">
        <img src="assets/icons_stickers_die_cut.png" alt="icon" />
        <p>Custom Stickers</p>
      </Link>
      <Link className="box-grid box-grid-padding" to="/labels">
        <img src="assets/icons_labels_clear_roll.png" alt="icon" />
        <p>Custom roll label</p>
      </Link>
      <Link className="box-grid box-grid-padding" to="/magnets">
        <img src="assets/icons_stickers_magnets.png" alt="icon" />
        <p>Magnets</p>
      </Link>
      <Link className="box-grid box-grid-padding" to="/banners">
        <img src="assets/icons_stickers_banners.png" alt="icon" />
        <p>Banners</p>
      </Link>
    </div>
  );

  return body;
}
