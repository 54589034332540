/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import ItemLibrary from '../itemLibrary';
import './styles.scss';
import getLibraryProducts from '../../services/library-products';
import Loader from '../loader';

export default function GridLibraryHome() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    setLoading(true);
    getLibraryProducts()
      .then((data) => {
        // Seleccionar solo los últimos 4 elementos
        const lastFourProducts = data.slice(-4);
        setProducts(lastFourProducts);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  }, []);

  let content;

  if (loading) {
    content = (
      <div className="content-loader">
        <Loader />
      </div>
    );
  } else if (error) {
    content = <h3>There was an error getting the library items</h3>;
  } else {
    content = (
      <div className="content-items-library-home" data-aos="fade-up" data-aos-delay="400">
        {products.map((product, index) => (
          <ItemLibrary key={index} item={product} />
        ))}
      </div>
    );
  }

  return content;
}
