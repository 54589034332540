import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

function getQueryParams(params) {
  let queryParams = '';
  if (params) {
    Object.keys(params).forEach((item) => {
      const simbolConcat = queryParams === '' ? '?' : '&';
      queryParams += `${simbolConcat}${item}=${params[item]}`;
    });
  }
  return queryParams;
}

export default function getCategoriesById({ idCategory, query }) {
  return axios({
    method: 'get',
    url: `${API_URL}/categories/${idCategory}${getQueryParams(query)}`,
  })
    .then((resp) => resp.data);
}
