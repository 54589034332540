import React from 'react';
import { Link } from 'react-router-dom';

export default function UserCreated() {
  return (
    <div className=" loading">
      <div className="empty-cart-content">
        <div>
          <img src="assets/cart-empty.svg" alt="empty cart" />
        </div>
        <div className="txt-empty">
          <h3>User created</h3>
          <p>Check your email. We have sent an email for you to confirm your username.</p>
          <Link className="btn-gral btn-clean-cart" type="button" to="/">Return Home</Link>
        </div>
      </div>
    </div>
  );
}
