import axios from 'axios';

export function changeStatusOrder(hash, status) {
  return axios({
    method: 'post',
    data: { status },
    url: `${process.env.REACT_APP_API_URL}/order/change-status/${hash}`,
  })
    .then((resp) => resp.data);
}

export function getOrder(hash) {
  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_API_URL}/order/${hash}`,
  })
    .then((resp) => resp.data);
}

export default {
  changeStatusOrder,
  getOrder,
};
