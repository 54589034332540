import React from 'react';
import './styles.scss';

export default function FollowUsInstagram() {
  return (
    <div className="follow-us-container">
      <div className="follow-us-content">
        <img src="/assets/related1.png" alt="form" data-aos="zoom-in" data-aos-delay="400" />
        <img src="/assets/related2.png" alt="form" data-aos="zoom-in" data-aos-delay="600" />
        <img src="/assets/related3.png" alt="form" data-aos="zoom-in" data-aos-delay="600" />
        <img src="/assets/related2.png" alt="form" data-aos="zoom-in" data-aos-delay="400" />
      </div>
    </div>
  );
}
