import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import jwtDecode from 'jwt-decode';
import { Link } from 'react-router-dom';
import { getUsersById, getUserOrderById, changeUserPassword } from '../../services/users';
import Loader from '../../components/loader';
import './styles.scss';

export default function UserProfile() {
  const [loading, setLoading] = useState((false));
  const [orders, setOrders] = useState([]);
  const [user, setUser] = useState([]);
  const [newPassword, setNewPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [errorPassword, setErrorPassword] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const token = localStorage.getItem('token') || '';
  const decode = jwtDecode(token);
  useEffect(() => {
    setLoading(true);
    getUserOrderById({
      idUser: decode.sub,
    })
      .then((data) => {
        setLoading(false);
        setOrders(data);
        getUsersById({
          idUser: decode.sub,
        })
          .then((secondData) => {
            setLoading(false);
            setUser(secondData);
          });
      });
  }, []);

  function userData() {
    return user.map((users) => {
      return (
        <div className="user-profile-forms-container">
          <div className="span-input-container-user-profile">
            <span>First Name</span>
            <div className="input">{users.firstName}</div>
          </div>
          <div className="span-input-container-user-profile">
            <span>Surname</span>
            <div className="input">{users.surName}</div>
          </div>
          <div className="span-input-container-user-profile">
            <span>E-mail</span>
            <div className="input">{users.email}</div>
          </div>
          <div className="span-input-container-user-profile">
            <span>Address</span>
            <div className="input">{users.address}</div>
          </div>
          <div className="span-input-container-user-profile">
            <span>Zip Code</span>
            <div className="input">{users.zipCode}</div>
          </div>
        </div>
      );
    });
  }

  function userOrdersTable(id) {
    return orders.map((order) => {
      return id === order.id && (
        <div className="orders" key={order.id}>
          <p>Product Name</p>
          <div>
            <span>Quantity</span>
            <span>{order.priceTotal}</span>
          </div>
          <div>
            <span>Price</span>
            <span>{order.priceTotal}</span>
          </div>
          <div>
            <span>Files</span>
            <span>Attached File</span>
          </div>
        </div>
      );
    });
  }

  function submitChangedPassword() {
    setErrorPassword(false);
    setLoading(true);
    if (newPassword !== repeatPassword) {
      setErrorPassword(true);
      setLoading(false);
      setErrorMsg('The passwords are not the same');
    }
    changeUserPassword(oldPassword, newPassword)
      .then((data) => {
        if (data.error) {
          setLoading(false);
          setErrorPassword(true);
          setErrorMsg(data.error);
        }
        setLoading(false);
        setErrorPassword(true);
        setErrorMsg('Password changed sucessfully');
        setNewPassword('');
        setOldPassword('');
        setRepeatPassword('');
        setInterval(() => {
          setErrorPassword(false);
        }, 6000);
      });
  }
  function renderChangePassword() {
    if (newPassword !== '' && repeatPassword !== '' && oldPassword !== '') {
      return (
        <div className="span-input-container-user-profile">
          <span>Confirm changes</span>
          <button type="button" className="btn-gral" onClick={() => submitChangedPassword()}>Save</button>
        </div>
      );
    }
    return null;
  }
  function userOrders() {
    return orders.map((order) => {
      return (
        <div className="user-profile-forms-container">
          <div className="span-container-user-profile">
            <hr className="order-divider" />
            <span>
              ORDER
              {' '}
              {order.id}
              <Link className="btn-gral go-details-btn" to={`/order-confirm?order=${order.hash}$-${order.id}`}><p>Go to Details</p></Link>
            </span>

            <Moment format="DD-MM-YYYY - HH:mm">
              {order.createdAt}
            </Moment>
            {' '}
            hs
            {' '}
            - Total
            <span>
              {' '}
              $
              {order.priceTotal}
            </span>
          </div>
          {userOrdersTable(order.id)}
        </div>
      );
    });
  }

  let body = (
    <>
      <div className="user-profile-container">
        <h2>My profile</h2>
        <div className="user-profile-info">
          <h3>Personal Info</h3>
          {userData()}
        </div>
      </div>
      <div className="user-profile-container">
        <div className="user-profile-info">
          <h3>Change Password</h3>
          <div className="user-profile-password-container">
            <div className="span-input-container-user-profile">
              <span>Your password</span>
              <input type="password" placeholder="Your password" onChange={(e) => setOldPassword(e.target.value)} />
            </div>
            <div className="span-input-container-user-profile">
              <span>New password</span>
              <input type="password" placeholder="New password" onChange={(e) => setNewPassword(e.target.value)} />
            </div>
            <div className="span-input-container-user-profile">
              <span>Repeat password</span>
              <input type="password" placeholder="Repeat password" onChange={(e) => setRepeatPassword(e.target.value)} />
            </div>
            {renderChangePassword()}
          </div>
          {errorPassword ? (<p>{errorMsg}</p>) : <></>}
        </div>
      </div>
      {orders.length >= 1 && (
        <div className="user-orders-container">
          <div className="user-orders-info">
            <h3>My Orders</h3>
            <div className="user-profile-orders-container">
              {userOrders()}
            </div>
          </div>
        </div>
      )}
    </>
  );

  if (loading) {
    body = (
      <Loader />
    );
  }

  return (
    <>
      {body}
    </>
  );
}
