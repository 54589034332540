/* eslint-disable no-param-reassign */
import React, { useState, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import './styles.scss';
import Loader from '../loader';
import { Context } from '../../context';
import { createCartLibraryItem } from '../../services/cart';

export default function LibraryDetail() {
  const [quantity, setQuantity] = useState(50);
  const [loading, setLoading] = useState(false);
  const sessionToken = localStorage.getItem('sessionToken');
  const history = useHistory();
  const { cart, setCart } = useContext(Context);
  const location = useLocation();

  const sizeText = location.state.width && location.state.height
    ? `${location.state.width}" x ${location.state.height}"`
    : ' N/A';

  const formatPrice = (price) => {
    if (!price) return '';
    return parseFloat(price).toFixed(2);
  };

  const createItem = () => {
    const item = {
      id: location.state.id,
      quantity,
      categoryId: 5,
    };

    if (!sessionToken) {
      history.push('/');
      return;
    }

    setLoading(true);
    createCartLibraryItem(sessionToken, item)
      .then((data) => {
        data.cartItem.price = formatPrice(data.cartItem.price);
        setLoading(false);
        setCart((cartActual) => ({
          ...cartActual,
          items: cart.items.concat([data.cartItem]),
          itemsCount: cart.itemsCount + 1,
        }));
        history.push('/cart');
      })
      .catch(() => {
        setLoading(false);
        history.push('/');
      });
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="content-select">
      <div className="box-selector">
        <h2>Size</h2>
        <div>{sizeText}</div>
      </div>
      <div className="box-selector">
        <h2>Select a quantity</h2>
        <input
          type="number"
          id="quantityCustom"
          placeholder="Enter quantity"
          className="input-quantity"
          min="0"
          value={quantity}
          onChange={(e) => setQuantity(Number(e.target.value))}
        />
      </div>
      <button type="button" className="btn-gral" onClick={createItem}>
        Continue to cart
      </button>
    </div>
  );
}
