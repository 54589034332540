import React, { useState, useEffect } from 'react';
import './styles.scss';
import getLibraryCategories from '../../services/library-categories';
import Loader from '../loader';

export default function FilterLibrary({ setFilterSelected, selectCategoryId }) {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setLoading(true);
    setError(false);
    getLibraryCategories()
      .then((data) => {
        setCategories(data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  }, []);

  function isActiveButton(id) {
    if (id === selectCategoryId) {
      return 'active';
    }
    return '';
  }

  let bodyCategory = categories.map((category) => {
    return (
      <li
        key={`filter-${category.id}`}
      >
        <button
          onClick={() => setFilterSelected(category.id)}
          type="button"
          className={`${isActiveButton(category.id)}`}
        >
          {category.name}
        </button>
      </li>
    );
  });

  if (loading) {
    bodyCategory = (
      <>
        <div className="content-loader">
          <Loader />
        </div>
      </>
    );
  }

  if (error) {
    bodyCategory = (
      <h3>There was an error getting the categories</h3>
    );
  }

  return (
    <>
      <ul className="filter-library">
        {bodyCategory}
      </ul>
    </>
  );
}
