import axios from 'axios';

export default function validateSession(sessionToken) {
  let completeUrl = '';
  if (sessionToken) {
    completeUrl = `?sessionToken=${sessionToken}`;
  }
  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_API_URL}/session${completeUrl}`,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
    .then((resp) => resp.data);
}
