const stickersIdList = [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17];
const transferStickerId = 18;
const roundedLabelsIdList = [19, 20, 22, 24];
const stickerSheetId = 26;
const circleLabelsIdList = [21, 23, 25];
const magnetsIdList = [27, 28];
const bannersIdList = [29, 30, 31, 32, 33];

function firstRestriction(width, height) {
  if (width > 50 && height > 50) {
    return {
      state: false,
      width: 'Max width is 50"',
      height: 'Max height is 50"',
    };
  }
  return {
    state: true,
    width: '',
    height: '',
  };
}

function secondRestriction(width, height) {
  if ((width === 50 && height > 120) || (height === 50 && width > 120)) {
    return {
      state: false,
      width: 'If one dimension is 50", the other cannot exceed 120"',
      height: '',

    };
  }
  return {
    state: true,
    width: '',
    height: '',
  };
}

function transferStickerRestriction(width, height) {
  const restriction = width > 50 || height > 50;
  if (restriction) {
    return {
      state: false,
      width: 'Max width is 50"',
      height: 'Max height is 50"',
    };
  }
  return {
    state: true,
    width: '',
    height: '',
  };
}

function magnetRestriction(width, height) {
  const restriction1 = width <= 28 && height <= 80;
  const restriction2 = width <= 80 && height <= 28;
  if (restriction1 || restriction2) {
    return {
      state: true,
      width: '',
      height: '',
    };
  }
  return {
    state: false,
    width: 'Max width can be 28" or 80"',
    height: 'Max height can be 28" or 80"',
  };
}

function labelRestriction(width, height) {
  const restriction1 = width <= 8 && height <= 13;
  const restriction2 = width <= 13 && height <= 8;
  if (restriction1 || restriction2) {
    return {
      state: true,
      width: '',
      height: '',
    };
  }
  return {
    state: false,
    width: 'Max width can be 8" or 13"',
    height: 'Max height can be 13" or 8"',
  };
}

function roundedLabelRestriction(width, height) {
  const restriction = width === height && (width <= 8 && height <= 8);
  if (restriction) {
    return {
      state: true,
      width: '',
      height: '',
    };
  }
  return {
    state: false,
    width: 'Max width must be 8"',
    height: 'Max height must be 8"',
  };
}

function sheetsRestriction(width, height) {
  const restriction1 = width <= 12 && height <= 18;
  const restriction2 = width <= 18 && height <= 12;
  if (restriction1 || restriction2) {
    return {
      state: true,
      width: '',
      height: '',
    };
  }
  return {
    state: false,
    width: 'Max width can be 12" or 18"',
    height: 'Max width can be 18" or 12"',
  };
}

export default function validateCalculatorInputs(id, width, height) {
  const parsedId = parseInt(id, 10);

  let validatedSize = {
    state: false,
    width: 'Error',
    height: 'Error',
  };
  switch (true) {
    case stickersIdList.includes(parsedId):
      validatedSize = {
        state: true,
        width: '',
        height: '',
      };

      if (!firstRestriction(width, height).state) {
        validatedSize = firstRestriction(width, height);
      }
      if (!secondRestriction(width, height).state) {
        validatedSize = secondRestriction(width, height);
      }
      return validatedSize;

    case parsedId === transferStickerId:
      validatedSize = transferStickerRestriction(width, height);
      break;
    case magnetsIdList.includes(parsedId):
      validatedSize = magnetRestriction(width, height);
      break;

    case roundedLabelsIdList.includes(parsedId):
      validatedSize = labelRestriction(width, height);
      break;

    case circleLabelsIdList.includes(parsedId):
      validatedSize = roundedLabelRestriction(width, height);
      break;

    case stickerSheetId === parsedId:
      validatedSize = sheetsRestriction(width, height);
      break;
    case bannersIdList.includes(parsedId):
      validatedSize = {
        state: true,
        width: '',
        height: '',
      };
      break;
    default:
      break;
  }

  return validatedSize;
}
